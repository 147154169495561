@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/fonts/SFPRODISPLAYREGULAR.OTF")
}
@font-face {
  font-family: "SF Pro Display Bold";
  src: url("./assets/fonts/SFPRODISPLAYSEMIBOLD.OTF");
}
html {
  scroll-behavior: smooth; 
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'SF Pro Display', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth; 
  max-width: 100vw;
  overflow-x: hidden;
}

a {
  cursor: pointer;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  transition: background-color 0.5s ease;
}


.hero-btn-wrapper:focus-within {
  border: 2px solid #3366ff;
}


.waitlist-wrapper {
  width: 100vw;
  height: 100vh;
  background: rgba(3, 37, 140, 0.1);
  backdrop-filter: blur(14px);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 700;
}

.waitlist-main-modal-section {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10vh
}

.waitlist-wrapper .modal-close {
  background-color: #fff;
  padding: 12px;
  border-radius: 100%;
  margin-bottom: 18px;
}

.waitlist-modal {
  background-color: #fff;
  max-width: 600px;
  border-radius: 12px;
  padding: 48px;
  /* margin-top: 60px; */
}

.waitlist-modal .modal-title {
  font-size: 28px;
  font-weight: 600;
  text-align: center;
}

.waitlist-modal .highlight {
  font-weight: bold;
  color: #0081EB;
}
.waitlist-modal .modal-desc {
  font-size: 22px;
  text-align: center;
  margin-top: 18px;
}

.modal-more-info {
  background-color: #F3F6FF;
  padding: 22px;
  border-radius: 10px;
  margin-top: 32px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.modal-more-info h3 {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
.modal-more-info p {
  text-align: center;
  margin-top: 16px;
}

.modal-share-flex {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: center;
}

.modal-share-flex button {
  display: flex;
  align-items: center;
  padding: 16px 28px !important;
  color: #fff;
  font-weight: 500;
  border-radius: 300px;
}
.modal-share-flex button img {
  padding-right: 8px;
}

.modal-share-flex button.modal-social-btn {
  background-color: #0081EB;
  margin-right: 18px;
}

.modal-share-flex button.modal-social-btn:hover {
  background-color: #40a4f7;
}

.modal-share-flex button.modal-copy-btn {
  background-color: #07834B;
}

.modal-share-flex button.modal-copy-btn:hover {
  background-color: #09aa62;
}

.email-error {
  color: red;
  margin-left: 18px;
  margin-top: 10px;
  font-weight: 400;
}


@media screen and (max-width: 650px) {
  .waitlist-main-modal-section {
    max-width: 600px;
    width: 95vw;
  }
  .waitlist-modal {
    padding: 22px;
  }
  .waitlist-modal .modal-title {
    font-size: 22px;
  }
  .waitlist-modal .modal-desc {
    font-size: 20px;
    margin-top: 16px;
  }
  .modal-more-info {
    padding: 16px;
  }
  .modal-more-info h3 {
    font-size: 18px;
  }
  .modal-share-flex button {
    padding: 12px 21px !important;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
  }
  .modal-share-flex button {
    margin-bottom: 20px;
  }
  .waitlist-modal {
    max-height: calc(90vh - 15vh);
    overflow-y: auto;
  }
  .mobile-manual-bold-text {
    font-weight: 900;
    letter-spacing: -0.8px;
    font-family: 'SF Pro Display Bold';
  }
  .showcase-img {
    margin-top: 80px;
  }
}

@media screen and (max-width: 500px) {
  .hero-btn-wrapper {
    max-width: 100vw;
  }
}

/* smaller screen */
@media screen and (max-width: 355px) {
  p.hero-desc {
    font-size: 18px;
  }
  .incentive-icon {
    height: 30px;
  }
  .hero-btn-wrapper {
    flex-wrap: wrap;
    border: 0px;
    background-color: transparent;
    border-radius: 0;
    height: fit-content;
  }
  .hero-btn-wrapper input {
    border: 1px solid;
    border-radius: 90px;
    margin-bottom: 12px;
    height: 50px;
    text-align: center;
  }
  .hero-btn-wrapper button {
    height: 50px;
    width: 100%;
    justify-content: space-evenly;
    margin-left: 0px;
    margin-right: 0px;
    font-size: 16px;
  }
  .nav-btn {
    padding-left: 22px;
    padding-right: 22px;
  }
}

@media screen and (max-width: 314px) {
  .nav-btn {
    padding-left: 16px;
    padding-right: 16px;
    font-size: 12px;
  }
  .navbar-logo {
    width: 80px !important;
  }
  .social-icons-row a {
    width: 30px;
    height: 30px;
  }
  .social-icons-row svg {
    width: 30px;
    height: 30px;
  }
}

@media screen and (max-width: 750px) {
  button.get-access-btn {
    padding-left: 18px;
    padding-right: 18px;
    border-radius: 100px;
    height: 46px;
    font-size: 15px;
  }
}
.get-access-btn {
  background-color: #3366FF;
  color: #fff;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 100px;
  height: 60px;
  font-size: 18px;
}




/* SPINNER */
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;
  margin-right: 10px;
}
.lds-ripple div {
  position: absolute;
  border: 2px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 16px;
    left: 16px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 32px;
    height: 32px;
    opacity: 0;
  }
}